<template>
	<v-card flat class="wr_grey_1">
		<v-card-title v-if="show_heading" class="wr_blue_1--text font-weight-black">
			{{ $t("app.keywords") }}</v-card-title
		>

		<v-card-text>
			<v-chip-group column>
				<v-chip
					v-for="keyword in mindMapKeywords"
					:key="keyword.id"
					color="info"
					:text-color="isExistsInList(keyword.name) ? 'white' : ''"
					:outlined="!isExistsInList(keyword.name)"
					@click.stop="importKeyword(keyword.name)"
				>
					<v-icon v-if="isExistsInList(keyword.name)" left
						>$vuetify.icons.values.check</v-icon
					>
					{{ keyword.name }}
				</v-chip>
			</v-chip-group>
		</v-card-text>
	</v-card>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { findFromArray } from "@/utils/helpers";

export default {
	name: "PlanSearchStrategyKeywordsImport",

	props: {
		show_heading: {
			default: true,
		},
	},

	computed: {
		...mapState({
			mindMapKeywords: (state) => state.keyword.mindmap_keywords,
			list: (state) => state.keyword.list,
		}),
		...mapGetters({
			isExistsInList: "keyword/isExistsInList",
		}),
	},

	mounted() {
		this.getMindmapKeywords();
	},

	methods: {
		async getMindmapKeywords() {
			try {
				this.$loader.start();

				await this.$store.dispatch("keyword/mindMapKeywords");
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async importKeyword(keyword) {
			try {
				this.$loader.start();

				// Create this as a new keyword only if not created already
				if (!this.isExistsInList(keyword)) {
					await this.$store.dispatch("keyword/create", {
						name: keyword,
						selected: false,
					});
				}
				// Else, find keyword's id by name because mind map keyword has uuid, so get id from list
				else {
					let result = findFromArray(this.list, "name", keyword);
					if (result) {
						await this.$store.dispatch("keyword/del", {
							id: result.id,
						});
					}
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
